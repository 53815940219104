import React from "react"
import Layout from "../components/layout"
import {Container, Row, Col} from "react-bootstrap";
import Img from "gatsby-image";
import Carousel from "react-bootstrap/Carousel";

export default ({data}) => {
  const post = data.markdownRemark;
  const buildImage = (image) => {
    if (!image) return;
    return(
      <Carousel.Item key={image.id}><Img fluid={image.childImageSharp.fluid}/></Carousel.Item>
    );
  };
  return (
    <Layout>
      <Container>
        <h1 className={'text-center'}>{post.frontmatter.title}</h1>
        <Row>
          <Col sm="12" md="6">
            <Carousel>
              {Object.values(post.fields).map(buildImage)}
            </Carousel>
          </Col>
          <Col sm="12" md="6">
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      id
      fields {
        image {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image3 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image4 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image5 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image6 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image7 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image8 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image9 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
        image10 {
          childImageSharp {
            fluid(maxWidth: 510) {
              ...GatsbyImageSharpFluid
            }
          }
          id
        }
      }
    }
  }
`;
