import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Container, Row, Col } from "react-bootstrap"

const Layout = ({children}) =>
{
  return (
    <div className={"site-container"}>
      <Header/>
      <div className="wrap">
        <main role="main">
          <Container fluid>
            <Row noGutters>
              <Col>
                <section>
                  {children}
                </section>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
      <Footer/>
    </div>
  );
};

export default Layout