import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap"

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(sort: {fields: fields___slug}) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
            }
            id
          }
        }
        file(name:{eq: "logo3-150x70"}) {
          childImageSharp {
            fixed(width: 150, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  const handleSelect = href => window.navigate(href);

  return (
    <header role="banner">
      <Container>
        <Row>
          <Col xs={12}>
            <Navbar bg="white" expand="lg">
              <Navbar.Brand href="/">
                <Img
                  fixed={data.file.childImageSharp.fixed}
                  alt={data.site.siteMetadata.title + ` logo`}
                  title={data.site.siteMetadata.title}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className={"justify-content-end"}>
                <Nav className="mr-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/about">About</Nav.Link>
                  <NavDropdown title="Services" id="nav-dropdown" onSelect={handleSelect}>
                    <NavDropdown.Item href="/services">Our Services</NavDropdown.Item>
                    <NavDropdown.Divider />
                    {data.allMarkdownRemark.nodes.map(node => (
                      <NavDropdown.Item key={node.id} href={node.fields.slug}>{node.frontmatter.title}</NavDropdown.Item>
                    ))}
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/services/additional-services">Additional Services</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>

    </header>
  )
};

export default Header
