import React from "react";
import { Container, Row, Col } from "react-bootstrap"

const Footer = () =>
  <footer role="contentinfo" className={"footer"}>
    <Container>
      <Row>
        <Col>
          <address>
            1292 Shady Ln NE<br/>
            Keizer, Oregon 97303<br/>
            <a href={"tel:5039307649"}>(503) 930-7649</a>
          </address>
          <small>Copyright &copy; <time>2018</time></small>
        </Col>
        <Col>
          <ul>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="https://www.google.com/maps/dir//rugged+cross+auto+detail/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x54bffe155f978937:0x345f1e957baba1e9?sa=X&ved=2ahUKEwjfyP78tt7mAhXB7Z4KHfoFBQ0Q9RcwDnoECAgQEA">Get Directions</a></li>
          </ul>
        </Col>
        <Col className="payment-types">
          <p>We accept: cash, check, Visa, MasterCard, Discover, American Express.</p>
        </Col>
      </Row>
    </Container>
  </footer>;

export default Footer
